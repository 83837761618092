
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import Login from "./pages/loginPage/login";
import auth from "./services/authService";
import NewUserDashboard from "./pages/dashboardPage/dashboard";
import Dispute from "./pages/disputePage/Dispute";
import NBFCVerify from "./pages/NbfcVerifyPage/nbfcVerify";
import AuthRoute from "./components/authRoute";
import AdBlockerWarning from "./components/adBlockerWarning";
import NBFCCompanyClientVerify from "./pages/NbfcCompanyVerifyPage/nbfcCompanyVerify";
import FooterComponent from "./components/footerComponent";
import Logout from "./components/logout";
import AdminPanelNBCF from "./pages/adminPage/adminPanelNBFC";

function App() {
  const theme = useSelector((state) => state.theme.theme);
  const [user, setUser] = useState(auth.getUser().UserLogin);

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  return (
    // <>
    //   <Routes>
    //     <Route path="/login" element={<Login />} />
    //     <Route path='/dashboard' element={<NewUserDashboard/>}/>
    //     <Route path='/verifyClient' element={<NBFCVerify/>}/>
    //     <Route path='/dispute' element={<Dispute/>}/>
    //     <Route path='/' element={<Login/>}/>
    //   </Routes>
    // </>

    <>
        <AdBlockerWarning />
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<Login />} />

            {/* Protect the following routes */}
            <Route
                path="/dashboard"
                element={
                    <AuthRoute>
                        <NewUserDashboard />
                    </AuthRoute>
                }
            />
            <Route
                path="/verifyClient"
                element={
                    <AuthRoute>
                        <NBFCVerify />
                    </AuthRoute>
                }
            />
            <Route
                path="/verifyCompanyClient"
                element={
                    <AuthRoute>
                        <NBFCCompanyClientVerify />
                    </AuthRoute>
                }
            />
            <Route
                path="/dispute"
                element={
                    <AuthRoute>
                        <Dispute />
                    </AuthRoute>
                }
            />

            <Route
                path="/AdminPanel"
                element={
                    <AuthRoute>
                        <AdminPanelNBCF />
                    </AuthRoute>
                }
            />
        </Routes>
    </>
  );
}

export default App;
